<template>
  <v-row>
    <v-col md="12" cols="12">
      <v-btn
        small
        @click="dialogCrearHerramienta = true"
        color="success"
        class="mx-3 my-3"
        ><v-icon left>mdi-plus</v-icon>Registrar nuevo modelo</v-btn
      >
      <v-sheet elevation="10" class="mb-3 py-4">
        <v-text-field
          
          class="mx-4 "
          hide-details
          label="Buscar"
          v-model="buscar"
        ></v-text-field>
      </v-sheet>
      <v-data-table
        :headers="cabecera"
        :items="items"
        :search="buscar"
        class="elevation-5"
      ></v-data-table>
    </v-col>

    <v-dialog
      v-model="dialogCrearHerramienta"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>Crear nuevo Modelo</v-card-title>
        <v-card-text>
          <v-form ref="formCrearHerramienta" v-model="crearHerramienta">
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  label="Nombre modelo"
                  v-model="nuevaModelo.name"
                  :rules="requerido"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="warning"
            @click="
              dialogCrearHerramienta = false;
              $refs.formCrearHerramienta.reset();
            "
            outlined
            >cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!crearHerramienta"
            color="primary"
            @click="crearNuevaMarca"
            >GUARDAR</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import axios from "axios";
import 'sweetalert2/dist/sweetalert2.min.css';

export default {
  name: "herramientas-panel",
  data() {
    return {
      dialogCrearHerramienta: false,
      crearHerramienta: false,
      buscar:"",
      cabecera: [
        { text: "Modelo ", value: "name" },
        { text: "numero identificacion", value: "id" },
      ],
      items: this.$store.state.modelos,
      nuevaModelo: {
        name: "",
      },
      requerido: [(v) => !!v || "Dato Obligatorio"],
    };
  },

  methods: {
    crearNuevaMarca:function(){
      axios.post(`${process.env.VUE_APP_BASE_URL}/api/item/newModel`, this.nuevaModelo).then((response) => {
        this.dialogCrearHerramienta = false;
        this.$swal.fire("Se a creado un nuevo modelo, ya esta disponible FZJP");
    });
    },
  },
  mounted(){

  }
};
</script>
<style >
.swal2-title { 
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 20px ! important; 
  color: orange ! important; }

  .swal2-confirm {
   
  }
  
  .swal2-styled{

  }
</style>
